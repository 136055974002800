Vue.component("header-component", {
    data: function () {
        return {
            scrolled: false,
            menuExpanded: false
        }
    },
    mounted: function () {
        let that = this;
        window.addEventListener('scroll', function () {
            that.watchScrollPosition();
        });

        this.watchScrollPosition();
    },
    methods: {
        watchScrollPosition: function () {
            let that = this;
            const scrollTop = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);

            if (scrollTop > 50) {
                that.scrolled = true;
            } else {
                that.scrolled = false;
            }
        },
    },
});