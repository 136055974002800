Vue.component("client-slider", {
    data: function() {
        return {
            flkty: null,
            sliderOptions: {},
            device: 'sm',
        };
    },
    mounted: function() {
        this.setSliderOptions();
        this.initClientSlider();
        this.watchWindowWidth();
    },
    methods: {
        initClientSlider() {
            this.flkty = new Flickity(this.$refs.slider, this.sliderOptions);
        },
        setSliderOptions() {
            this.sliderOptions = {
                wrapAround: true,
                autoPlay: true,
                cellAlign: 'center',
                prevNextButtons: false,
                pageDots: false,
                lazyLoad: 4,
            };

            if ( matchMedia('screen and (min-width: 768px)').matches ) {
                this.sliderOptions.cellAlign = 'left';
            }
        },
        watchWindowWidth() {
            let that = this;

            window.addEventListener('resize', function(){
                console.log('resized!');
                if (that.device === 'sm' && window.innerWidth > 768) {
                    that.device = 'md';
                    that.setSliderOptions();

                    that.flkty.destroy();
                    that.initClientSlider();
                }
                if (that.device === 'md' && window.innerWidth < 768) {
                    that.device = 'sm';
                    that.setSliderOptions();

                    that.flkty.destroy();
                    that.initClientSlider();
                }
            });
        }
    }
});