Vue.component("slider-component", {
    data: function() {
        return {
            flkty: null,
            device: 'sm',
        };
    },
    mounted: function() {
        this.initSlider();
        this.watchWindowWidth();
    },
    methods: {
        initSlider() {
            this.flkty = new Flickity(this.$refs.slider, {
                wrapAround: true,
                bgLazyLoad: true,
                adaptiveHeight: true,
                // setGallerySize: false,
                pageDots: false,
                arrowShape: "M38.5108 25.8971L31.9068 19.293L0.585938 50.6138L31.9068 81.9346L38.5108 75.3305L18.4639 55.2836L100.586 55.2836V45.944L18.4639 45.944L38.5108 25.8971Z",
            });
            let that = this;

            window.addEventListener('resize', function(){
                that.flkty.resize();
            });
        },
        watchWindowWidth() {
            let that = this;

            window.addEventListener('resize', function(){
                if (that.device === 'sm' && window.innerWidth > 768) {
                    that.device = 'md';

                    that.flkty.resize();
                }
                if (that.device === 'md' && window.innerWidth < 768) {
                    that.device = 'sm';
                    that.flkty.resize();
                }
            });
        }
    }
});